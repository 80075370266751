import { useEffect, useState, useMemo, } from 'react';
import { BrowserRouter as Router, Routes, Route, useParams, useLocation } from 'react-router-dom';

import SelectOption from '../../../components/selectOption';
import Base from '../../../utils/base';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default function AttendanceRewardModal({ student_arr, reward_arr, onSubmit, }){
  function useQuery(){
		const {search} = useLocation()
		return useMemo(() => new URLSearchParams(search), [search]);
	}

	var base = new Base()
	let query = useQuery()

	const [student_selected, set_student_selected] = useState('')
	const [student_selected_data, set_student_selected_data] = useState({})
	const [date_selected, set_date_selected] = useState(new Date())
	const [date_selected_moment, set_date_selected_moment] = useState('')
	const [attendance_score_arr, set_attendance_score_arr] = useState([{id : 0, name : 0}, {id : 1, name : 1}, {id : 'P', name : 'P'}])
	const [reward_score_arr, set_reward_score_arr] = useState([{id : 0, name : 0}, {id : 1, name : 1}])
	const [attendance_reward_arr, set_attendance_reward_arr] = useState([])
	const [attendance_date_arr, set_attendance_date_arr] = useState([])
	const [attendance_point_data, set_attendance_point_data] = useState({})
	const [attendance_error, set_attendance_error] = useState('')

  const isWeekday = (date: Date) => {
    const day = date.getDay()
    return day !== 0 && day !== 6
  }

	useEffect(() => {
		if(student_selected != ''){
			for(let student of student_arr){
				if(student_selected == student.id){
					set_student_selected_data(student)
					break
				}
			}
		}
		else
			set_student_selected_data({})
	}, [student_selected, student_arr, ])

	useEffect(() => {
		set_date_selected_moment(base.moment(date_selected))
	}, [date_selected, ])

	useEffect(() => {
		if(student_selected_data.id != null && date_selected_moment != '')
			get_point_transaction()
	}, [date_selected_moment, student_selected_data, ])

	async function get_point_transaction(){
		var response = await base.request(
			'/point/transaction/student?class_student_id=' + student_selected_data.id +
			'&date=' + date_selected_moment.format('YYYY-MM-DD')
		)
		if(response != null){
			if(response.status == 'success'){
				var data = response.data
				set_attendance_error('')
				set_attendance_date_arr(data.arr_date)
				set_attendance_reward_arr(data.arr_reward)
				set_attendance_point_data(data.arr_point_transaction)
			}
			else
				set_attendance_error(response.message)
		}
		else
			set_attendance_error('Server Error')
	}

	async function submit(){
		var data_post = {
			class_student : student_selected_data,
		}

		var arr_attendance_reward = []
		for(var x in attendance_date_arr){
			var data = {}
			data.date = attendance_date_arr[x]
			var arr = []
			for(var y in attendance_reward_arr){
				var data_reward = attendance_point_data[attendance_date_arr[x]][attendance_reward_arr[y].id]
				if(data_reward != null && data_reward.amount != '-'){
					data_reward.reward = {id : attendance_reward_arr[y].id}
					arr.push(data_reward)
				}
			}
			data.arr = arr
			arr_attendance_reward.push(data)
		}
		data_post.arr_attendance_reward = arr_attendance_reward
		var url = '/point/transaction'

		var response = await base.request(url, 'put', data_post)
		if(response != null){
			if(response.status == 'success'){
				base.$('#attendanceRewardModal').modal('hide')
				onSubmit(date_selected_moment.format('YYYY-MM'))
				set_attendance_date_arr([])
				set_student_selected('')
				set_date_selected('')
			}
			else
				set_attendance_error(response.message)
		}
		else
			set_attendance_error('Server Error')
	}

	async function onChangeRadio(index_score, index_date, index_reward){
		var data_score = reward_score_arr
		if(reward_arr[index_reward].name === 'Attendance')
			data_score = attendance_score_arr

		var attendance_point_selected = attendance_point_data[attendance_date_arr[index_date]][attendance_reward_arr[index_reward].id]

		var score = data_score[index_score].id

		if(attendance_point_selected != null){
			if(attendance_point_selected.id == null && attendance_point_selected.amount === data_score[index_score].id)
				score = '-'
			attendance_point_selected.amount = score
		}
		else{
			attendance_point_data[attendance_date_arr[index_date]][attendance_reward_arr[index_reward].id] = {}
			attendance_point_data[attendance_date_arr[index_date]][attendance_reward_arr[index_reward].id].amount = score
		}

		base.update_object(attendance_point_data, set_attendance_point_data, score, attendance_point_data[attendance_date_arr[index_date]][attendance_reward_arr[index_reward].id].amount)
	}

  return(
    <div className="modal fade" id="attendanceRewardModal" tabIndex="-1" aria-labelledby="attendanceRewardModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-xl">
        <div className="modal-content rounded border-0 shadow-sm">
          <div className="modal-body p-0">

            <div className='p-3 pt-4 pb-5'>
              <div className='mb-3'>
                <h5 className='m-0'>
									<i className="bi bi-chat-square-dots-fill mr-3" style={{color : '#00000066'}}></i>
									Add Attendance & Reward
								</h5>
              </div>

              <div className='mt-3 pb-3' style={{borderBottom : '1px solid #EAEAEA'}}>
                <div className='row m-0'>
                  <div className='col-auto'>
                    <label>Student</label>
                    <SelectOption data_arr={student_arr}
											selected={student_selected}
											title={'Student'}
											changeInput={(value) => set_student_selected(value)} />
                  </div>

                  <div className='col-auto'>
                    <label>Date</label>
										<div>
											<DatePicker
												selected={date_selected}
												onChange={date => set_date_selected(date)}
												maxDate={new Date()}
												timeFormat="HH:mm"
												dateFormat="dd MMMM yyyy"
												filterDate={isWeekday}/>
										</div>
                  </div>
                </div>
              </div>

              {
                attendance_error === '' ?
									<div>
										<div className="table-responsive mt-3">
											<table className="table w-100">
												<thead>
													<tr>
														<th>Date</th>
														{ attendance_reward_arr.map((data, index) => <th key={index}>{data.name}</th>) }
													</tr>
												</thead>

												<tbody>
													{
														attendance_date_arr.map((data, index)=>
															<tr key={index}>
																<td>
																	{base.moment(data).format('ddd, DD-MM-YYYY')}
																</td>
																{
																	attendance_reward_arr.map((data_reward, index_reward)=>
																		<td key={index_reward}>
																			{
																				attendance_point_data != null &&
																				attendance_point_data[data] != null &&
																				<div className='row m-0'>
																					{
																						data_reward.name !== 'Spiritual Growth' ?
																							data_reward.name === 'Attendance' ?
																								attendance_score_arr.map((data_score, index_score)=>
																									<RadioScore key={index_score}
																										name={data_score.name}
																										id={data_score.id}
																										is_checked={attendance_point_data[data][data_reward.id] != null ? data_score.id == attendance_point_data[data][data_reward.id].amount : ''}
																										radio_id={'radio-' + data + '-' + data_reward.id + '-' + data_score.id}
																										changeRadio={() => onChangeRadio(index_score, index, index_reward)} />
																								)
																							:
																								reward_score_arr.map((data_score, index_score)=>
																									<RadioScore key={index_score}
																										name={data_score.name}
																										id={data_score.id}
																										is_checked={attendance_point_data[data][data_reward.id] != null ? data_score.id == attendance_point_data[data][data_reward.id].amount : ''}
																										radio_id={'radio-' + data + '-' + data_reward.id + '-' + data_score.id}
																										changeRadio={() => onChangeRadio(index_score, index, index_reward)} />
																								)
																						:
																							attendance_point_data[data][data_reward.id] != null &&
																							<p className='m-0'>{attendance_point_data[data][data_reward.id].amount}</p>
																					}
																				</div>
																			}
																		</td>
																	)
																}
															</tr>
														)
													}
														<tr></tr>
												</tbody>
											</table>
										</div>

										{
											attendance_date_arr.length > 0 &&
											<div className='mt-4'>
												<button className='btn btn-primary rounded shadow-sm px-5' onClick={() => submit()}>Submit</button>
											</div>
										}
									</div>
                :
                  <div className='mt-3 text-center'>
                    <h2 className='m-0 mt-2' style={{color : '#575A89', fontSize : '1.75rem'}}>{attendance_error}</h2>
                  </div>
              }
            </div>

          </div>
        </div>
      </div>
    </div>
  )
}

function RadioScore({name, id, is_checked, radio_id, changeRadio}){
  return(
    <div className='col-auto px-1'>
      <div className="form-check">
        <input className="form-check-input" type="radio" id={radio_id} value={id} checked={is_checked ? 'checked' : ''} onChange={()=>changeRadio()} />

        <label className="form-check-label" htmlFor={radio_id} style={{color : 'black'}}>
          {name}
        </label>
      </div>
    </div>
  )
}