import { useEffect, useState, useMemo } from 'react';
import Base from '../../../utils/base';
import Header from '../../../components/header';
import CardSubject from '../../../components/cardSubject';
import NoData from '../../../components/NoData'
import ActiveUnactiveData from '../../../components/activeUnactiveData';
import { BrowserRouter as Router, Routes, Route, useParams, useLocation } from 'react-router-dom';

import Select from 'react-select'
import ModalConfirm from './components/confirmModal';
import ModalSubmit from '../checkAssignment/modalSubmit';
import NotAssigned from '../../../components/NotAssigned';
import LoadingData from '../../../components/loading';
import ModalSubmitAll from './components/modalSubmitAll';
import 'summernote'

export default function SubjectLesson(props){
	var base = new Base()

	function useQuery(){
		const {search} = useLocation()
		return useMemo(() => new URLSearchParams(search), [search]);
	}

	let query = useQuery()

	const [user_data, set_user_data] = useState({id : '', name : '', email : '', phone : '', image : {image_display : base.img_no_profile}, current_academic_year : {id : '', name : ''}})

	const [filter_grade_arr, set_filter_grade_arr] = useState([])
	const [filter_grade_selected, set_filter_grade_selected] = useState('')
	const [filter_class_selected, set_filter_class_selected] = useState('')

	const [filter_subject_arr, set_filter_subject_arr] = useState([])
	const [filter_subject_selected, set_filter_subject_selected] = useState([])
	const [filter_lesson_arr, set_filter_lesson_arr] = useState([])
	const [filter_lesson_selected, set_filter_lesson_selected] = useState([])

	const [data_arr, set_data_arr] = useState([])

	const [activity_type_selected, set_activity_type_selected] = useState('')
	const [activity_assessment_rule_selected, set_activity_assessment_rule_selected] = useState('')
	const [activity_rule_selected, set_activity_rule_selected] = useState('')
	const [is_modal_btn_disable, set_is_modal_btn_disable] = useState(false)
	const [grade_skill_arr, set_grade_skill_arr] = useState([])
	const [rule, set_rule] = useState([])
	const [numerical_score, set_numerical_score] = useState('')
	const [assignment_status_data, set_assignment_status_data] = useState('')
	const [teacher_notes, set_teacher_notes] = useState('')
	const [assignment_submitted_id, set_assignment_submitted_id] = useState('')
	const [activity_subject_selected, set_activity_subject_selected] = useState('')
	const [rule_detail_arr, set_rule_detail_arr] = useState([])
	const [radio_project_selected, set_radio_project_selected] = useState('major_revision')
	const [class_student_id, set_class_student_id] = useState('')
	const [class_student, set_class_student] = useState({})
	const [selected_project_agreement, set_selected_project_agreement] = useState({})
	const [from_system, set_from_system] = useState(false)

	const [class_student_arr, set_class_student_arr] = useState([])

	const [selected_academic_year, set_selected_academic_year] = useState('')

	const [is_student_online_selected, set_is_student_online_selected] = useState(false)

	const [is_loading, set_is_loading] = useState(true)
	const [is_loading_filter, set_is_loading_filter] = useState(true)
	const [is_loading_data, set_is_loading_data] = useState(false)
	const [is_grade_skill_notes_empty, set_is_grade_skill_notes_empty] = useState(false)
	const [is_loading_grade_skill, set_is_loading_grade_skill] = useState(false)

	const [submit_type, set_submit_type] = useState('')

	const [is_disable_btn_modal, set_is_disable_btn_modal] = useState(false)
	const [lesson_selected, set_lesson_selected] = useState(null)
	const [index_lesson_selected, set_index_lesson_selected] = useState('')
	const [selected_agreement_id, set_selected_agreement_id] = useState('')
	const [selected_agreement_data, set_selected_agreement_data] = useState({})
	const [selected_student_id, set_selected_student_id] = useState('')
	const [status_activity_selected, set_status_activity_selected] = useState('')

	const [assignment_grade_id, set_assignment_grade_id] = useState('')

	const [grade_skill_avg, set_grade_skill_avg] = useState(0)
	const [grade_skill_total_score, set_grade_skill_total_score] = useState(0)

	const [allSubmit_activity_selected, set_allSubmit_activity_selected] = useState({id : '', arr_class_student : []})
	const [disable_all_btn, set_disable_all_btn] = useState(false)

	const [subject_selected, set_subject_selected] = useState('')

	useEffect(async ()=>{
		if(lesson_selected != null)
			base.$('#modalConfirm').modal('show')
	}, [lesson_selected, index_lesson_selected])

	useEffect(async ()=>{
		var check_user = await base.checkAuth()
		set_user_data(check_user.user_data)
		set_selected_academic_year(check_user.user_data.current_academic_year.id)

		base.$('#modalSubmit').on('hidden.bs.modal', function (event) {
			set_activity_type_selected('')
			set_teacher_notes('')
			set_activity_assessment_rule_selected('')
			base.$('.summernote').summernote('destroy');
		})

	}, [])

	useEffect(async ()=>{
		if(user_data.id !== ''){
			if(selected_academic_year !== ''){
				await get_filter_data_arr('grade')
				await get_filter_data_arr('lesson')

				if(query.get('grade_id') != null){
					await set_filter_grade_selected(query.get('grade_id'))
				}
				if(query.get('class_id') != null){
					await set_filter_class_selected(query.get('class_id'))
				}
				set_is_loading(false)
			}
		}
	}, [user_data, selected_academic_year])

	useEffect(async ()=>{
		if(from_system){
			set_from_system(false)
			return
		}

		if(grade_skill_arr.length > 0 && class_student.id != null){
			var arr = JSON.parse(JSON.stringify(grade_skill_arr))
			for(let temp of arr){
				for(let skill of temp.arr_skill){
					for(let grade_skill of class_student.arr_grade_skill){
						if(grade_skill.project_skill.project_id == selected_project_agreement.project_id && grade_skill.project_skill.skill.id == skill.id){
							set_teacher_notes(grade_skill.comment)
							skill.score = grade_skill.score
							break
						}
					}
				}
			}
			set_from_system(true)
			set_grade_skill_arr(arr)

		}
	}, [class_student, grade_skill_arr, selected_project_agreement])

	useEffect(async ()=>{
		if(filter_grade_selected != '')
			get_filter_data_arr('subject', filter_grade_selected)
	}, [filter_grade_selected])

	useEffect(async ()=>{
		if(query.get('grade_id') != null && data_arr.length === 0){
			set_is_loading_data(true)
			set_data_arr([])
			filterBtn()
		}
	}, [filter_grade_selected, filter_class_selected, filter_subject_selected, filter_lesson_selected])

	useEffect(()=>{
		if(submit_type === 'post_grade' && assignment_submitted_id !== '')
			submitGrading()
	}, [submit_type, assignment_submitted_id])

	useEffect(async()=>{
		if(activity_type_selected !== ''){
			if(activity_assessment_rule_selected !== ''){
				set_is_loading_grade_skill(false)
				get_grade_skill()
				get_rule()
				set_grade_skill_avg(0)
				set_grade_skill_total_score(0)

				base.$('#modalSubmit').modal('show')

				const summernote = window.$('.summernote')
				window.$('.summernote').summernote({
					height : 200,
					callbacks: {
						onChange: function(contents, $editable) {
							changeInputModal(contents, 'notes')
						}
					}
				})
			}
		}
		else{
			base.$('#modalSubmit').modal('hide')
		}
	}, [activity_type_selected, activity_assessment_rule_selected])

	async function get_filter_data_arr(type, id=''){
		var url = ''
		if(type === 'grade')
			url = '/academic-year/class?academic_year_id=' + selected_academic_year
		else if(type === 'subject')
			url = '/subject?grade_id=' + id + '&class_id=' + filter_class_selected
		else if(type === 'lesson')
			url = '/lesson/all'

		var response = await base.request(url)
		if(response != null){
			if(response.status == 'success'){
				var data = response.data
				if(type === 'grade'){
					var data1 = data.data
					for(var x in data1)
						data1[x].name = data1[x].grade.name + ' ' + data1[x].name

					set_filter_grade_arr(data1)
				}
				else if(type === 'subject'){
					var subject_arr = data.data

					for(var x in subject_arr){
						subject_arr[x].label = subject_arr[x].name
						subject_arr[x].value = subject_arr[x].id
					}

					var filter_url = []
					if(query.get('subject_selected') != null){
						filter_url = query.get('subject_selected').split(',')
						var query_data = []

						for(var x in filter_url){
							for(var y in subject_arr){
								if(subject_arr[y].id === filter_url[x])
									query_data.push(subject_arr[y])
							}
							set_filter_subject_selected(query_data)
						}
					}

					set_filter_subject_arr(subject_arr)
				}
				else if(type === 'lesson'){
					for(var x in data){
						data[x].label = data[x].name
						data[x].value = data[x].id
					}

					var filter_url_lesson = []
					if(query.get('lesson_selected') != null){
						filter_url_lesson = query.get('lesson_selected').split(',')
						var query_data = []

						for(var x in filter_url_lesson){
							for(var y in data){
								if(data[y].id === filter_url_lesson[x])
									query_data.push(data[y])
							}
							set_filter_lesson_selected(query_data)
						}
					}
					set_filter_lesson_arr(data)
				}
			}
		}
	}

	async function changeFilter(index, type, value=''){
		if(type === 'grade'){
			set_filter_grade_selected(filter_grade_arr[index].grade.id)
			set_filter_class_selected(filter_grade_arr[index].id)
			set_filter_subject_selected([])
			set_filter_lesson_selected([])
		}
		else if(type === 'subject')
			set_filter_subject_selected(value)
		else if(type === 'lesson')
			set_filter_lesson_selected(value)
	}

	async function filterBtn(){
		var flag = 1

		if(filter_grade_selected === '' || filter_subject_selected.length === 0 || filter_lesson_selected.length === 0)
			flag = 0

		if(flag){
			set_is_loading_filter(true)
			var subject_id = []
			var subject_name_selected = ''
			var subject_selected_id = ''

			for(var x in filter_subject_selected){
				subject_id.push(filter_subject_selected[x].id)
				subject_name_selected += filter_subject_selected[x].name + (parseInt(x) === filter_subject_selected.length-1 ? '' : ', ')
				subject_selected_id += filter_subject_selected[x].id + (parseInt(x) === filter_subject_selected.length-1 ? '' : ',')
			}

			set_subject_selected(subject_name_selected)

			var lesson_id = []
			var lesson_selected_id = ''
			for(var x in filter_lesson_selected){
				lesson_id.push(filter_lesson_selected[x].id)
				lesson_selected_id += filter_lesson_selected[x].id + (parseInt(x) === filter_lesson_selected.length-1 ? '' : ',')
			}

			window.history.pushState(
				{}, null, '/subject-lesson?grade_id=' + filter_grade_selected +
				'&class_id=' + filter_class_selected +
				'&subject_selected=' + subject_selected_id +
				'&lesson_selected=' + lesson_selected_id
			)

			var url = '/assignment/group?arr_subject_id=' + JSON.stringify(subject_id) +
				'&arr_lesson_id=' + JSON.stringify(lesson_id) +
				'&grade_id=' + filter_grade_selected +
				'&class_id=' + filter_class_selected +
				'&type=current_academic_year'

			var response = await base.request(url)
			setTimeout(() => {
				set_is_loading_data(false)
			}, 750);

			if(response != null){
				if(response.status == 'success'){
					var data = response.data
					for(var data of response.data){
						for(var assignment of data.arr_assignment_agreement){
							assignment.icon = 'bi bi-puzzle-fill'
							assignment.activity_name = ''
							if(assignment.data_type === 'assignment'){
								assignment.icon = (assignment.assignment_type.data === 'quiz' ? 'bi bi-puzzle-fill' : assignment.assignment_type.data === 'discussion' ? 'bi bi-easel-fill' : assignment.assignment_type.data === 'ungraded' ? 'bi bi-book-half' : '')

								assignment.activity_name = assignment.name
							}
							else{
								assignment.activity_name = assignment.title
								assignment.deadline_date = assignment.meeting_at
							}

							assignment.is_done = true

							for(var class_student of assignment.arr_class_student){
								var status_activity = ''

								if(class_student.last_assignment_submitted != null)
									status_activity = class_student.last_assignment_submitted.assessment_status.data

								class_student.status_activity_arr = [
									{title : 'NO', bg_color : '#999999', status : '', is_checked : (status_activity === '' ? true : false)},
									{title : 'OK', bg_color : '#60B158', status : 'done', is_checked : (status_activity === 'done' ? true : false)},
									{title : 'MJ', bg_color : '#CF91FF', status : 'need_much_correction', is_checked : (status_activity === 'need_much_correction' ? true : false)},
									{title : 'MN', bg_color : '#F2994A', status : 'need_correction',  is_checked : (status_activity === 'need_correction' ? true : false)},
									{title : 'S', bg_color : '#0085FF', status : 'on_checking',  is_checked : (status_activity === 'on_checking' ? true : false)},
									{title : 'HS', bg_color : '#FC5A5A', status : 'blocked',  is_checked : (status_activity === 'blocked' ? true : false)},
								]

								if(class_student.last_assignment_submitted == null || class_student.last_assignment_submitted.assessment_status.data !== 'done')
									assignment.is_done = false
							}
						}
					}

					set_data_arr(response.data)
					set_is_loading_filter(false)

					set_class_student_arr(response.arr_class_student)
				}
			}
		}
	}

	async function confirmLesson(btn_from, index=0){
		if(btn_from === 'list'){
			set_index_lesson_selected(index)
			set_lesson_selected(data_arr[index])
		}
		else if(btn_from === 'modal'){
			var url = '/assignment/agreement/confirm'

			var data_post = {
				id : lesson_selected.id,
			}

			set_is_disable_btn_modal(true)

			var response = await base.request(url, 'put', data_post)
			if(response != null){
				if(response.status == 'success'){
					var data_index = data_arr[index_lesson_selected]
					data_index.confirmed_user = user_data
					base.update_array(data_arr, set_data_arr, data_index, index_lesson_selected)
					base.$('#modalConfirm').modal('hide')
				}
			}
		}
	}

	async function editActivity(index, index_assignment){
		var data = data_arr[index].arr_assignment_agreement[index_assignment]
		window.location.href='/subject-lesson/edit-activity?id=' + data.id + '&type=' + data.data_type
	}



	async function get_rule(){
		var url = '/assessment/rule?id=' + activity_assessment_rule_selected
		var response = await base.request(url)
		if(response != null){
			if(response.status == 'success'){
				var data = response.data
				set_rule(data.name)
				set_rule_detail_arr(data.detail)
			}
		}
	}

	async function get_grade_skill(){
		var url = '/skill/category?id=&type=' + selected_agreement_data.data_type +
			'&agreement_id=' + selected_agreement_data.id +
			'&subject_id=' + activity_subject_selected +
			'&assignment_submitted_id=' + assignment_submitted_id +
			'&grade_id=' + assignment_grade_id
		var response = await base.request(url)

		setTimeout(() => {
			set_is_loading_grade_skill(false)
		}, 1000);
		if(response != null){
			if(response.status == 'success'){
				var data = response.data.data

				for(var x in data){
					var arr_skill = data[x].arr_skill
					for(var y in arr_skill){
						arr_skill[y].score = ''
					}
				}
				set_grade_skill_arr(data)


			}
		}
	}

	async function changeStatus(index, index_assignment, index_class_student, index_status){
		var data_index = data_arr[index]
		var status_selected = data_index.arr_assignment_agreement[index_assignment].arr_class_student[index_class_student].status_activity_arr[index_status]
		var status = status_selected.status
		var assignment_agreement = data_arr[index].arr_assignment_agreement[index_assignment]
		var class_student = assignment_agreement.arr_class_student[index_class_student]
		set_assignment_grade_id(data_index.arr_assignment_agreement[index_assignment].arr_class_student[index_class_student].class_model.grade.id)
		set_is_student_online_selected(class_student.is_online)
		set_selected_agreement_data(assignment_agreement)
		set_class_student(class_student)
		set_selected_project_agreement(data_index)
		if(data_index.arr_assignment_agreement[index_assignment].arr_class_student[index_class_student].last_assignment_submitted != null){

			var last_submitted = data_index.arr_assignment_agreement[index_assignment].arr_class_student[index_class_student].last_assignment_submitted

			// if(last_submitted.assessment_status.data !== 'done'){
			// }
			if(status === 'done'){
				set_activity_subject_selected(data_index.subject.id)
				set_class_student_id(class_student.id)
				if(class_student.last_assignment_submitted != null){
					set_assignment_submitted_id(class_student.last_assignment_submitted.id)
					set_assignment_status_data(class_student.last_assignment_submitted.assessment_status.data)
				}
				else{
					set_assignment_status_data('not_done')
				}
				if(assignment_agreement.data_type === 'assignment'){
					set_activity_type_selected(assignment_agreement.assignment_type.data)
					set_activity_assessment_rule_selected(assignment_agreement.assessment_rule_id)
				}
				else if(assignment_agreement.data_type === 'task'){
					set_activity_type_selected((assignment_agreement.type === 'presentation' ? 'discussion' : 'upload'))
					set_activity_assessment_rule_selected(assignment_agreement.project_agreement.assessment_rule_id)
				}
				set_status_activity_selected(status)
			}
			else{
				var url = '/assessment/assignment'
				var data_upload = {
					id : last_submitted.id,
					status : status,
				}

				var response = await base.request(url, 'put', data_upload)
				if(response != null){
					if(response.status == 'success'){
						filterBtn()
					}
				}
			}
		}
		else{
			if(status === 'done'){
				set_activity_subject_selected(data_index.subject.id)
				set_class_student_id(class_student.id)
				set_selected_student_id(class_student.user_id)
				set_selected_agreement_id(assignment_agreement.id)
				if(assignment_agreement.data_type === 'assignment'){
					set_activity_type_selected(assignment_agreement.assignment_type.data)
					set_activity_assessment_rule_selected(assignment_agreement.assessment_rule_id)
				}
				else if(assignment_agreement.data_type === 'task'){
					set_activity_type_selected((assignment_agreement.type === 'presentation' ? 'discussion' : 'upload'))
					set_activity_assessment_rule_selected(assignment_agreement.project_agreement.assessment_rule_id)
				}
			}
			else{
				var url = '/assessment/assignment'
				var data_upload = {
					status : status,
				}

				if(assignment_agreement.data_type === 'task')
					data_upload.task_agreement = {id : assignment_agreement.id}
				else
					data_upload.assignment_agreement = {id : assignment_agreement.id}

				data_upload.user_id = class_student.user_id

				var response = await base.request(url, 'post', data_upload)
				if(response != null){
					if(response.status == 'success'){
						filterBtn()
						base.$('#modalSubmit').modal('hide')
					}
				}
			}
		}
	}

	async function changeInputModal(val, type){
		if(type === 'grade')
			set_activity_rule_selected(val)
		if(type === 'notes'){
			set_is_grade_skill_notes_empty(false)
			set_teacher_notes(val)
		}
	}

	async function submitGrading(){
		set_is_modal_btn_disable(true)
		var url = ''
		var data_upload = {}
		var method = 'put'
		var flag = 1

		if(activity_type_selected === 'quiz'){
			url = '/assessment/assignment'
			data_upload = {
				id : assignment_submitted_id,
				comment : teacher_notes,
			}

			if(assignment_submitted_id !== '')
				data_upload.id = assignment_submitted_id
			else{
				method = 'post'
				data_upload.assignment_agreement = {id : selected_agreement_id}
				data_upload.user_id = selected_student_id
			}

			var status = ''
			if(!is_student_online_selected)
				status = 'done'
			else if(radio_project_selected === 'major_revision')
				status = 'need_much_correction'
			else if(radio_project_selected === 'minor_revision')
				status = 'need_correction'
			else
				status = 'done'

			if(status_activity_selected !== '')
				status = status_activity_selected


			data_upload.status = status

			if(rule === 'Numerical')
				data_upload.score = numerical_score
			else if(activity_rule_selected !== '')
				data_upload.assessment_rule_detail = {id : activity_rule_selected}
		}
		else  if(activity_type_selected === 'ungraded'){
			url = '/assessment/assignment'
			data_upload = {}
			if(assignment_submitted_id !== '')
				data_upload.id = assignment_submitted_id
			else{
				method = 'post'
				data_upload.assignment_agreement = {id : selected_agreement_id}
				data_upload.user_id = selected_student_id
			}
		}
		else if(activity_type_selected === 'discussion'){
			var arr_skill = []
			for(var x in grade_skill_arr){
				var skill_data = grade_skill_arr[x].arr_skill
				for(var y in skill_data)
					arr_skill.push({
						skill : {id : skill_data[y].id},
						score : skill_data[y].score,
					})
			}

			for(var x in arr_skill){
				if(arr_skill[x].score === ''){
					flag = 0
					break
				}
			}

			if(teacher_notes === ''){
				flag = 0
				set_is_grade_skill_notes_empty(true)
			}

			url = '/grade/skill'

			if(assignment_submitted_id !== ''){
				data_upload.assignment_submitted = {id : assignment_submitted_id}
				data_upload.class_student = {id : class_student_id}
				data_upload.comment = teacher_notes
				data_upload.arr_skill = arr_skill
			}
			else{
				url = '/assessment/assignment'
				data_upload.task_agreement = {id : selected_agreement_id}
				data_upload.user_id = selected_student_id
				data_upload.type = 'new'
				data_upload.status = 'on_checking'
			}

			method = 'post'
		}
		else if(activity_type_selected === 'upload'){
			url = '/assessment/assignment'

			var status = status_activity_selected
			if(!is_student_online_selected)
				status = 'done'
			else if(radio_project_selected === 'major_revision')
				status = 'need_much_correction'
			else if(radio_project_selected === 'minor_revision')
				status = 'need_correction'
			else
				status = 'done'

			if(status_activity_selected !== '')
				status = status_activity_selected

			data_upload = {
				status : status,
				comment : teacher_notes,
			}

			if(assignment_submitted_id !== '')
				data_upload.id = assignment_submitted_id
			else{
				method = 'post'
				data_upload.assignment_agreement = {id : selected_agreement_id}
				data_upload.user_id = selected_student_id
			}
		}

		if(flag){
			var response = await base.request(url, method, data_upload)
			if(response != null){
				if(response.status == 'success'){
					if(assignment_submitted_id !== ''){
						filterBtn()
						base.$('#modalSubmit').modal('hide')
					}
					else{
						set_assignment_submitted_id(response.data.id)
						set_submit_type('post_grade')
					}
				}
			}
		}

		set_is_modal_btn_disable(false)
	}

	function changeScore(index, index_skill, val){
		var data_index = grade_skill_arr[index]
		var skill_data = data_index.arr_skill
		skill_data[index_skill].score = parseInt(val) <= 5 && parseInt(val) >= 1 ? val : ''
		base.update_array(grade_skill_arr, set_grade_skill_arr, data_index, index)
	}

	function changeNumerical(value){
		var indexValue = value.length - 1
		var score = numerical_score
		if(value.charCodeAt(indexValue) >= 48 && value.charCodeAt(indexValue) <= 57)
			score = value
		else if((indexValue < 0))
			score = ''
		else if(parseInt(value) > 100)
			score = 100
		set_numerical_score(score)
	}

	function doneAll(index, index_assignment){
		set_allSubmit_activity_selected(data_arr[index].arr_assignment_agreement[index_assignment])
		base.$('#modalSubmitAll').modal('show')
	}

	async function okAll(){
		set_disable_all_btn(true)
		var url = '/assessment/assignment'
		var data_upload = {}
		var method = 'post'
		var flag = 1

		var class_student = allSubmit_activity_selected.arr_class_student

		for(var x in class_student){
			if(class_student[x].last_assignment_submitted != null){
				method = 'put'
				data_upload.id = class_student[x].last_assignment_submitted.id
			}
			else{
				method = 'post'
				data_upload.assignment_agreement = {id : allSubmit_activity_selected.id}
				data_upload.user_id = class_student[x].user_id
			}
			data_upload.status = 'done'

			var response = await base.request(url, method, data_upload)
			set_disable_all_btn(false)
			if(response != null){
				if(response.status == 'success'){
					if(x == class_student.length-1){
						filterBtn()
						base.$('#modalSubmitAll').modal('hide')
						base.$('#modalSubmit').modal('hide')
					}
				}
			}
		}
	}

	return(
		<div className='row'>

			<div className='col-12'>
				<Header title={'Subject & Lesson Plan'} user_data={user_data} />
			</div>


			<div className='col-12 mt-5 pt-4'>
				{
					filter_grade_arr.length > 0 ?
						<div className='mx-0 mx-lg-3'>
							<div className='bg-white rounded p-3'>
								<div className='row'>
									<div className='col-12 col-lg'>
										<div className=''>
											<p className='m-0' style={{color : 'black'}}>Grade</p>
										</div>
										<div className='row mt-1'>
											{
												filter_grade_arr.map((data, index)=>
													<div className='col-12 col-lg-6 mb-2' key={index}>
														<div class="custom-control custom-radio">
															<input type="radio" id={"grade_radio" + index} name="customRadio" class="custom-control-input"
																onChange={(e)=>changeFilter(index, 'grade')}
																checked={filter_class_selected === data.id} />
															<label class="custom-control-label" htmlFor={"grade_radio" + index}>{data.name}</label>
														</div>
													</div>
												)
											}
										</div>
									</div>

									<div className='col-12 col-lg mt-2 mt-lg-0'>
										<div className='row'>
											<div className='col-12'>
												<p className='m-0' style={{color : 'black'}}>Subject</p>
											</div>
											<div className='col-12 mt-1'>
												<Select options={filter_subject_arr} value={filter_subject_selected} isMulti={true} onChange={(value)=>changeFilter(0, 'subject', value)} isOptionDisabled={()=>filter_subject_selected.length >= 2} />
											</div>
										</div>
									</div>

									<div className='col-12 col-lg mt-2 mt-lg-0'>
										<div className='row'>
											<div className='col-12'>
												<p className='m-0' style={{color : 'black'}}>Lesson</p>
											</div>
											<div className='col-12 mt-1'>
												<Select options={filter_lesson_arr} value={filter_lesson_selected} isMulti={true} onChange={(value)=>changeFilter(0, 'lesson', value)} isOptionDisabled={()=>filter_lesson_selected.length >= 4} />
											</div>
										</div>
									</div>

									<div className='col-12 col-lg-auto'>
										<button type='button' className='btn btn-primary rounded shadow-sm px-4 mt-4 w-100' onClick={()=>filterBtn()}>Filter</button>
									</div>
								</div>
							</div>

							{
								is_loading_data ?
								<LoadingData />
								:
								<div>
									{
										subject_selected !== '' &&
										<div className='mt-5 px-3'>
											<div className='row'>
												<div className='col-auto bg-white rounded p-3 px-5'>
													<p className='m-0' style={{fontFamily : 'InterBold'}}>{subject_selected}</p>
												</div>
											</div>
										</div>
									}

									{
										data_arr.length > 0 ?
											<div className='mt-5 px-0 px-lg-3'>
												{
													data_arr.map((data, index)=>
														<div className="table-responsive rounded mb-3">
															<table class="table w-100 table-borderless m-0">
																<thead style={{backgroundColor : '#EAEAEA'}}>
																	<tr>
																		<th className='align-middle td-fit-content'>
																			<div className=''>
																				<p className='m-0 text-primary'>{data.lesson.name}</p>
																			</div>

																			<div className=''>
																				<p className='m-0' style={{fontSize : '.75rem'}}>Confirmed by {(data.confirmed_user != null ? data.confirmed_user.name : '-')}</p>
																			</div>

																			<div className='mt-2'>
																				<button className='btn btn-sm btn-primary shadow-sm rounded px-3' onClick={()=>confirmLesson('list', index)}>Confirm</button>
																			</div>
																		</th>

																		{
																			data.arr_assignment_agreement.map((data_assignment, index_assignment)=>
																				<th className='align-middle' key={index_assignment}>
																					<div className='row m-0'>
																						<div className='col-12'>
																							<p className='m-0 text-center'>{data_assignment.activity_name}</p>
																							{
																								data_assignment.project_agreement != null &&
																								<p className='m-0 text-center'>{data_assignment.project_agreement.name}</p>
																							}
																						</div>
																						<div className='col-12'>
																							<div className='d-flex justify-content-center'>
																								<div className='py-1 px-3' style={{borderRadius : '5rem', backgroundColor : '#EBF8FF'}}>
																									<p className='m-0 text-center' style={{fontSize : '.75rem'}}>
																										{data_assignment.total_submitted} / {data_assignment.total_student}
																									</p>
																								</div>
																							</div>
																						</div>
																					</div>
																				</th>
																			)
																		}
																	</tr>
																</thead>

																<tbody className='bg-white'>
																	<tr>
																		<td></td>
																		{
																			data.arr_assignment_agreement.map((data_assignment, index_assignment)=>
																				<td className='align-middle d-flex justify-content-center'>
																					<div className='row'>
																						<div className='col px-2'>
																							<h5 className='m-0' style={{cursor : 'pointer'}}>
																								<i className="bi bi-pencil-square" style={{color : '#0085FF'}} onClick={()=>editActivity(index, index_assignment)}/>
																							</h5>
																						</div>

																						{
																							data_assignment.assignment_type != null && data_assignment.assignment_type.data === 'ungraded' &&
																							<div className='col px-2'>
																							{
																								!data_assignment.is_done ?
																									<h5 className='m-0' style={{cursor : 'pointer'}} onClick={()=>doneAll(index, index_assignment)}>
																										<i class="bi bi-check-square-fill" style={{color : '#999999'}}></i>
																									</h5>
																								:
																									<h5 className='m-0' style={{cursor : 'pointer'}}>
																										<i class="bi bi-check-square-fill" style={{color : '#0085FF'}}></i>
																									</h5>
																							}
																							</div>
																						}
																					</div>
																				</td>
																			)
																		}
																	</tr>

																	{
																		class_student_arr.map((data_class_student, index_class_student)=>
																			<tr key={index_class_student}>
																				<td><p className='m-0'>{data_class_student.user.name}</p></td>

																				{
																					data.arr_assignment_agreement.map((data_assignment, index_assignment)=>
																						<td className=''>
																							{
																								data_assignment.arr_class_student.map((data_class, index_class)=>
																									data_class.id === data_class_student.id &&
																									<div className="d-flex justify-content-center" key={index_class}>
																										<div className='row'>
																											{
																												data_class.status_activity_arr.map((data_status, index_status)=>
																													<div className={'col-12 col-md-6 col-lg-4 pr-2 pl-2 mb-1'} key={index_status}>
																														<div className='d-flex justify-content-center'
																															style={{cursor : 'pointer'}}
																															onClick={()=>changeStatus(index, index_assignment, index_class_student, index_status)}>
																															<div className='row'>
																																<div className='col-auto p-0 d-flex align-items-center'>
																																	<p className='m-0' style={{fontSize : '.7rem'}}>
																																		{
																																			data_status.is_checked ?
																																			<i class="bi bi-record-circle-fill"/>
																																			:
																																			<i class="bi bi-circle"/>
																																		}
																																	</p>
																																</div>

																																<div className='col pl-1 pr-0 d-flex align-items-center'>
																																	<div className='rounded px-2 text-center'
																																		style={{ backgroundColor : data_status.bg_color, }}>
																																		<p className='m-0 text-white' style={{fontSize : '.7rem'}}>{data_status.title}</p>
																																	</div>
																																</div>
																															</div>
																														</div>
																													</div>
																												)
																											}
																										</div>

																										<div className='mt-2 ml-3'>
																											<p className='m-0 text-center' style={{fontSize : '.75rem'}}>
																												Last Updated : {data_class.last_assignment_submitted != null ? base.moment(data_class.last_assignment_submitted.updated_at).format('DD/MM/YYYY HH:mm') : '-'}
																											</p>
																										</div>
																									</div>
																								)
																							}
																						</td>
																					)
																				}
																			</tr>
																		)
																	}
																</tbody>
															</table>
														</div>
													)
												}
											</div>
										: !is_loading_filter &&
											<div className='pt-5' style={{marginTop : '5rem'}}>
												<NotAssigned />
											</div>
									}
								</div>
							}

						</div>
					: !is_loading &&
						<div className='mt-5 pt-4'>
							<NotAssigned />
						</div>
				}
			</div>

			<ModalConfirm confirmLesson={()=>confirmLesson('modal')} is_disable_btn_modal={is_disable_btn_modal} />

			<ModalSubmit
				assignment_type={activity_type_selected}
				rule_detail_arr={rule_detail_arr}
				rule_selected={activity_rule_selected}
				changeInput={(val, type)=>changeInputModal(val, type)}
				submitGrading={()=>submitGrading()}
				is_modal_btn_disable={is_modal_btn_disable}
				grade_skill_arr={grade_skill_arr}
				changeScore={(index, index_skill, val)=>changeScore(index, index_skill, val)}
				rule={rule}
				numerical_score={numerical_score}
				changeNumerical={(value)=>changeNumerical(value)}
				assignment_status_data={assignment_status_data}
				teacher_notes={teacher_notes}
				set_radio_project={(value)=>set_radio_project_selected(value)}
				viewFrom={'subject-lesson'}
				is_student_online={is_student_online_selected}
				grade_skill_avg={grade_skill_avg}
				grade_skill_total_score={grade_skill_total_score}
				is_grade_skill_notes_empty={is_grade_skill_notes_empty}
				is_loading_grade_skill={is_loading_grade_skill}
				onChangeNotes={(value) => changeInputModal(value, 'notes')}/>

			<ModalSubmitAll okAll={()=>okAll()} disable_all_btn={disable_all_btn} />
		</div>
	)
}