import { useEffect, useState, useMemo, } from 'react';
import { BrowserRouter as Router, Routes, Route, useParams, useLocation } from 'react-router-dom';

import SelectOption from '../../../components/selectOption';
import Base from '../../../utils/base';


export default function HomeroomReportGrade({grade_subject_arr, grade_student_arr, grade_book_dtl_arr, toggleSubject, term_arr,}){
	function useQuery(){
		const {search} = useLocation()
		return useMemo(() => new URLSearchParams(search), [search]);
	}

	var base = new Base()
	let query = useQuery()

	const [arr_subject, set_arr_subject] = useState([])
	const [arr_student, set_arr_student] = useState([])
	const [arr_grade_book, set_arr_grade_book] = useState([])
	const [term_selected, set_term_selected] = useState(null)

	useEffect(() => {
		if(term_selected != null){
			set_arr_subject([])
			get_data()
		}
	}, [term_selected, ])

	useEffect(async () => {
		var check_user = await base.checkAuth()
		if(term_arr.length > 0)
			set_term_selected(check_user.user_data.current_term.id)
	}, [term_arr])

	async function get_data(){
		var response = await base.request(
			'/class/report-card?id=' + query.get('id') +
			'&term_id=' + term_selected
		)

		if(response != null){
			if(response.status == 'success'){
				for(var subject of response.data.arr_subject)
					subject.is_show = false
				response.data.arr_subject[0].is_show = true

				set_arr_subject(response.data.arr_subject)
				set_arr_student(response.data.arr_class_student)
				set_arr_grade_book(response.data.arr_grade_book_detail)
			}
		}
	}

	function onSubjectClicked(index){
		var arr = JSON.parse(JSON.stringify(arr_subject))
		for(var temp of arr)
			temp.is_show = false
		arr[index].is_show = true
		set_arr_subject(arr)
	}

	return(
		<div className="card rounded shadow-sm">
			<div className={"card-body p-0"}>

				<div className={'row m-0'}>
					<img className='rounded' src={base.img_borderTop_primary} style={{width : '100%', height : '.75rem'}} />
					<div className='col-12 p-3 pt-4'>
						<div className='row m-0 mb-3 pr-3'>
							<div className='col-12 col-lg'>
								<h5 className='m-0'><i className="bi bi-chat-square-dots-fill mr-3" style={{color : '#00000066'}}></i>Report Card Grade</h5>
							</div>

							<div className='col-12 col-lg-auto'>
								<SelectOption
									data_arr={term_arr}
									selected={term_selected}
									title={'Term'}
									changeInput={(value)=>set_term_selected(value)}/>
							</div>
						</div>

						<div className='m-0 mt-3'>
							{
								arr_subject.map((subject, index)=>
									<div>
										<div className='row p-3' style={{backgroundColor : '#EBEFE2', cursor : 'pointer'}} onClick={()=>onSubjectClicked(index)}>
											<div className='col d-flex align-items-center'>
												<p className='m-0'>
													Subject / Standard :
													<span className='m-0' style={{fontFamily : 'InterBold'}}>{subject.name}</span> /
													<span>{subject.minimum_pass_score}</span>
												</p>
											</div>

											<div className='col-auto d-flex align-items-center justify-content-end'>
												<i className={"fas fa-chevron-" + (subject.is_show ? 'up' : 'down')}/>
											</div>
										</div>

										{
											subject.is_show &&
											<div className="table-responsive">
												<table className="table">
													<thead>
														<tr>
															<td className='py-2'>No</td>
															<td className='py-2'>Student Name</td>
															<td className={'text-center py-2'} style={{backgroundColor : '#FFF2CA'}}>Final Score</td>
															{
																subject.arr_assignment.map((assignment, index_assignment) => <td key={index_assignment} className={'text-center'}>{assignment.name}</td>)
															}
														</tr>
													</thead>

													<tbody>
														{
															arr_student.map((student, index_student)=>
																<tr key={index_student}>
																	<td className='td-fit-content py-2'>{parseInt(index_student)+1}</td>
																	<td className='py-2'>{student.user.name}</td>
																	<td className={'text-center align-middle py-2'} style={{backgroundColor : '#FFF2CA'}}>
																		{
																			arr_grade_book[subject.id] != null &&
																			arr_grade_book[subject.id][student.id] != null &&
																			arr_grade_book[subject.id][student.id]['final_score'] != null &&
																			<p className='m-0'>{arr_grade_book[subject.id][student.id]['final_score'].average_score}</p>
																		}
																	</td>

																	{
																		subject.arr_assignment.map((assignment, index_assignment)=>
																			arr_grade_book[subject.id] != null &&
																			arr_grade_book[subject.id][student.id][assignment.id] != null &&
																			<td className='m-0 text-center'>
																				{arr_grade_book[subject.id][student.id][assignment.id].score}
																			</td>
																		)
																	}
																</tr>
															)
														}
													</tbody>
												</table>
											</div>
										}
									</div>
								)
							}
						</div>
					</div>
				</div>

			</div>
		</div>
	)
}