import { useEffect, useState } from 'react';
import Base from '../../../utils/base';


export default function HomeroomListStudent({student_arr, }){
  var base = new Base()

	const [search, set_search] = useState('')
	const [arr, set_arr] = useState([])
	const [arr_base, set_arr_base] = useState([])

	useEffect(() => {
		if(student_arr != null)
			set_arr_base(student_arr)
	}, [student_arr, ])

	useEffect(() => {
		var arr_temp = JSON.parse(JSON.stringify(arr_base))
		var arr_temp = arr_temp.filter((obj) => {
			return(JSON.stringify(obj).toLowerCase().includes(search.toLowerCase()))
		})
		set_arr(arr_temp)
	}, [arr_base, search, ])

  return(
    <div className=''>
			<div className="card rounded shadow-sm">
				<div className={"card-body p-0"}>

					<div className={'row m-0'}>
						<img className='rounded' src={base.img_borderTop_primary} style={{width : '100%', height : '.75rem'}} />
						<div className='col-12 p-3 pt-4'>
							<div className='mb-3 pr-3'>
								<h5 className='m-0'>
									<i className="bi bi-chat-square-dots-fill mr-3" style={{color : '#00000066'}}/>
									Student List
								</h5>
							</div>

							<div className="input-group border rounded">
								<div className="input-group-prepend">
									<span className="input-group-text bg-white border-0 bg-transparent pr-0" id="basic-addon1"><i className="bi bi-search"></i></span>
								</div>
								<input type="text" className="form-control border-0 bg-transparent" placeholder="Search" aria-describedby="basic-addon1" value={search} onChange={(e)=>set_search(e.target.value)} />
							</div>

							<div className="table-responsive mt-3">
								<table className="table">
									<thead>
										<tr>
											<th>ID</th>
											<th>Student Name</th>
											<th>L/P</th>
											<th>Phone Number</th>
											<th>City of Birth</th>
											<th>Date of Birth</th>
											{/* <th></th> */}
										</tr>
									</thead>

									<tbody>
										{
											arr.map((data, index)=>
												<tr key={index}>
													<td className='align-middle td-fit-content'>{data.id}</td>
													<td className='align-middle'>{data.name}</td>
													<td className='align-middle'>{(data.gender === 1 ? 'L' : 'P')}</td>
													<td className='align-middle'>{data.phone}</td>
													<td className='align-middle'>{data.birth_city.name}</td>
													<td className='align-middle'>{base.moment(data.birth_date).format('DD/MM/YYYY')}</td>
													{/* <td><button className='btn btn-primary rounded px-4'>View More</button></td> */}
												</tr>
											)
										}
									</tbody>
								</table>
							</div>
						</div>
					</div>

				</div>
			</div>

    </div>
  )
}