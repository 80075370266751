import { useEffect, useState, useMemo, } from 'react';
import { BrowserRouter as Router, Routes, Route, useParams, useLocation } from 'react-router-dom';

import Base from '../../../utils/base';


export default function HomeroomDashboard(props){
	function useQuery(){
		const {search} = useLocation()
		return useMemo(() => new URLSearchParams(search), [search]);
	}

	var base = new Base()
	let query = useQuery()

	const [rank_student_arr, set_rank_student_arr] = useState([])
	const [schedule_lesson_day_arr, set_schedule_lesson_day_arr] = useState([])
	const [schedule_lesson_time_arr, set_schedule_lesson_time_arr] = useState([])
	const [schedule_arr, set_schedule_arr] = useState([])
	const [school_subject_arr, set_school_subject_arr] = useState([])
	const [school_subject_arr_base, set_school_subject_arr_base] = useState([])
	const [search, set_search] = useState('')

	useEffect(() => {
		get_data()
	}, [])

	useEffect(() => {
		var arr_temp = JSON.parse(JSON.stringify(school_subject_arr_base))
		var arr_temp = arr_temp.filter((obj) => {
			return(JSON.stringify(obj).toLowerCase().includes(search.toLowerCase()))
		})
		set_school_subject_arr(arr_temp)
	}, [school_subject_arr_base, search, ])

	async function get_data(){
		var response = await base.request('/class/homeroom?id=' + query.get('id') + '&student_search=' + search)

		if(response != null){
			if(response.status == 'success'){
				for(var grade_rank of response.data.arr_grade_rank){
					grade_rank.class_student.user.image_display = base.img_no_profile
					if(grade_rank.class_student.user.file_name != null)
						grade_rank.class_student.user.image_display = base.url_photo('user', grade_rank.class_student.user.file_name)
				}
				set_rank_student_arr(response.data.arr_grade_rank)

				var student_arr = []
				for(var student of response.data.student){
					student.user.image_display = base.img_no_profile
					student.user.class_student_id = student.id
					if(student.user.file_name != null)
						student.user.image_display = base.url_photo('user', student.user.file_name)
					student_arr.push(student.user)
				}

				set_schedule_lesson_day_arr(response.data.arr_day)
				set_schedule_lesson_time_arr(response.data.arr_time)
				set_schedule_arr(response.data.arr_schedule)

				var assign_teacher = response.data.arr_assign_teacher
				var arr_school_subject = []
				for(var assign_teacher of response.data.arr_assign_teacher){
					var data_subject = assign_teacher.subject
					data_subject.teacher  = assign_teacher.teacher_assigned_str
					arr_school_subject.push(data_subject)
				}
				set_school_subject_arr(arr_school_subject)
				set_school_subject_arr_base(arr_school_subject)
			}
		}
	}

	return(
		<div>
			<div className="card rounded shadow-sm">
				<div className={"card-body p-0"}>

					<div className={'row m-0'}>
						<img className='rounded' src={base.img_borderTop_primary} style={{width : '100%', height : '.75rem'}} />
						<div className='col-12 p-3 pt-4'>
							<div className='row m-0 mb-3 pr-3'>
								<div className='col-12 col-lg px-0 px-lg-3'>
									<h5 className='m-0'>
										<i className="bi bi-chat-square-dots-fill mr-3" style={{color : '#00000066'}}/>
										Student Lesson
									</h5>
								</div>
							</div>

							<div className="table-responsive px-0 px-lg-3">
								<table className="w-100" style={{height : '10rem'}}>
									<tbody>
										<tr>
											<td className='position-sticky bg-white' style={{width : '4rem', left : 0}}></td>
											{
												schedule_lesson_time_arr.map((data_time, index_time) => <td style={{width : '4rem', fontSize : '.7rem'}} key={index_time}>{ data_time.id }</td>)
											}
										</tr>

										{
											schedule_lesson_day_arr.map((day_data, day_index)=>
												<tr key={day_index}>
													<td style={{width : '4rem', verticalAlign : 'middle', height : '2rem', fontSize : '.7rem', left : 0}} className="text-center position-sticky bg-white">{day_data.name}</td>

													{
														schedule_lesson_time_arr.map((data_time, index_time)=>
															schedule_arr[day_data.id] != null && (
																schedule_arr[day_data.id][data_time.id] != null && schedule_arr[day_data.id][data_time.id].type === 'event_schedule' && day_index === 0 && schedule_arr[day_data.id][data_time.id].flag ?
																	<td style={{height: '10rem'}} rowSpan={schedule_lesson_day_arr.length} colSpan={schedule_arr[day_data.id][data_time.id].span}>
																		<div className="rounded-lg w-100 h-100 py-1 px-2 d-flex align-items-center justify-content-center" style={{backgroundColor : '#EBEFE2', width : '4rem'}}>
																			<p className="m-0 font-weight-bold text-center" style={{color: '#B6C0A0', fontSize: '.7rem'}}>{schedule_arr[day_data.id][data_time.id].name}</p>
																		</div>
																	</td>
																:
																schedule_arr[day_data.id][data_time.id] != null && schedule_arr[day_data.id][data_time.id].type === 'schedule' && schedule_arr[day_data.id][data_time.id].flag ?
																	<td colSpan={schedule_arr[day_data.id][data_time.id].span} style={{height : '2rem', width : '4rem'}}>
																		<div className="rounded-lg h-100 w-100 py-1 px-2 d-flex align-items-center justify-content-center" style={{backgroundColor : '#EBEFE2', }}>
																			<p className="m-0 font-weight-bold" style={{color: '#B6C0A0', fontSize: '.7rem'}}>{ schedule_arr[day_data.id][data_time.id].subject.name }</p>
																		</div>
																	</td>
																: schedule_arr[day_data.id][data_time.id] == null &&
																	<td style={{height: '2rem'}}>
																		<div className="rounded-lg h-100 w-100" style={{backgroundColor: '#F7F7F7'}}></div>
																	</td>
															)
														)
													}
												</tr>
											)
										}
									</tbody>
								</table>

							</div>
						</div>
					</div>

				</div>
			</div>

			<div className="card rounded shadow-sm mt-5">
				<div className={"card-body p-0"}>

					<div className={'row m-0'}>
						<img className='rounded' src={base.img_borderTop_primary} style={{width : '100%', height : '.75rem'}} />
						<div className='col-12 p-3 pt-4'>
							<div className='row m-0 mb-3 pr-3'>
								<div className='col-12 col-lg px-0 px-lg-3'>
									<h5 className='m-0'>
										<i className="bi bi-chat-square-dots-fill mr-3" style={{color : '#00000066'}}/>
										School Subject
									</h5>
								</div>
							</div>

							<div className='row m-0'>
								<div className='col px-0 px-lg-3'>
									<div className="input-group border rounded">
										<div className="input-group-prepend">
											<span className="input-group-text bg-white border-0 bg-transparent pr-0" id="basic-addon1">
												<i className="bi bi-search"/>
											</span>
										</div>

										<input type="text" className="form-control border-0 bg-transparent" placeholder="Search" aria-describedby="basic-addon1" value={search} onChange={(e)=>set_search(e.target.value)} />
									</div>
								</div>

								{/* <div className='col-auto d-flex align-items-center'>
									<p className='m-0' style={{color : 'black'}}>
										<i className="bi bi-sort-up"/>
										Sort
									</p>
								</div> */}
							</div>

							<div className="table-responsive mt-3 px-0 px-lg-3">
								<table className="table table-fixed-lg">
									<thead>
										<tr>
											<th className=''>ID</th>
											<th className=''>Subject</th>
											<th className=''>Lesson Plan</th>
											<th className=''>Teacher Assigned</th>
										</tr>
									</thead>

									<tbody>
										{
											school_subject_arr.map((data, index)=>
												<tr key={index}>
													<td>{data.id}</td>
													<td>{data.name}</td>
													<td>{data.subject_type.name}</td>
													<td>{data.teacher}</td>
												</tr>
											)
										}
									</tbody>
								</table>
							</div>
						</div>
					</div>

				</div>
			</div>
		</div>
	)
}

function DashboardList({title, data_arr, type}){
	const base = new Base()
	return(
		<div className="card rounded shadow-sm h-100">
			<div className={"card-body p-0"}>

				<div className={'row m-0'}>
					<img className='rounded' src={base.img_borderTop_primary} style={{width : '100%', height : '.75rem'}} />
					<div className='col-12 p-3 pt-4 pb-5'>
						<div className='row m-0 mb-4'>
							<div className='col p-0 d-flex align-items-center'>
								<h5 className='m-0'>{title}</h5>
							</div>
						</div>

						{
							data_arr.length > 0 ?
								data_arr.map((data, index)=>
									<div className={'' + (index !== 0 ? ' mt-3' : '')} key={index}>
										<div className='row m-0'>
											{
												type === 'rank' ?
												<>
													<div className='col-auto p-0 d-flex align-items-center'>
														<p className='m-0' style={{color : 'black'}}>#{index+1}</p>
													</div>

													<div className='col-auto d-flex align-items-center justify-content-center'>
														<img src={data.class_student.user.image_display} style={{height : '3.5rem', width : '3.5rem', aspectRatio : 1, borderRadius : '3.5rem'}} />
													</div>

													<div className='col p-0 d-flex align-items-center'>
														<div>
															<p className='m-0 text-capitalize' style={{color : 'black', fontFamily : 'InterBold'}}>{data.class_student.user.name}</p>
															<p className='m-0 text-capitalize' style={{fontSize : '.75rem'}}>{data.class_student.user.id}</p>
														</div>
													</div>

													<div className='col p-0 d-flex align-items-center justify-content-end'>
														<h4 className='m-0 text-capitalize' style={{color : 'black', fontFamily : 'InterBold'}}>{data.total_score}</h4>
													</div>
												</>
												:
												<>
													<div className='col-auto p-0 d-flex align-items-center justify-content-center'>
														<div style={{height : '1.25rem', width : '1.25rem', border : '2px solid #8C9196', borderRadius : '.25rem'}}></div>
													</div>

													<div className='col d-flex align-items-center'>
														<p className='m-0 text-capitalize'>{data.title}</p>
													</div>
												</>
											}
										</div>
									</div>
								)
							:
							<div className='col-12 text-center'>
								<h6 className='m-0' style={{color : 'black'}}>No Data</h6>
							</div>
						}
					</div>
				</div>

			</div>
		</div>
	)
}